import { useState, useLayoutEffect } from "react";

function getDimensions() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

function useDimensions({ liveMeasure = true}) {
  const [dimensions, setDimensions] = useState({});

  useLayoutEffect(() => {
    const measure = () =>
      window.requestAnimationFrame(() =>
        setDimensions(getDimensions())
      );
    measure();

    if (liveMeasure) {
      window.addEventListener("resize", measure);
      window.addEventListener("scroll", measure);

      return () => {
        window.removeEventListener("resize", measure);
        window.removeEventListener("scroll", measure);
      };
    }
  }, []);

  return dimensions;
}

export default useDimensions;