import { useEffect, useState } from 'react';
import useDimensions from './useDimensions'

const useGridDetection = (props) => {
  const { breakpoints, gridOptions, location, totalItems, rowHeight } = props
  const dimensions = useDimensions({});
  const [rows, setRows] = useState();
  const [rowSize, setRowSize] = useState();
  const [detected, setDetected] = useState();
  const [height, setHeight] = useState();
  const [marginTop, setMarginTop] = useState(0);

  const findWidth = (dim, bp) => {
    const sortValues = (a, b) => (a[1] > b[1]);
    const keyValToArray = bp => e => ([e, bp[e]])
    const keys = Object.keys(bp)
    return keys
      .map(keyValToArray(bp))
      .sort(sortValues)
      .reduce((accum, elem, index) => {
        if (dim.width >= elem[1]) {
        return elem[0];
      }
      return accum;
    }, 'xs');
  }

  useEffect(() => {
    if (dimensions && dimensions.width !== undefined) {
      const d = findWidth(dimensions, breakpoints);
      setDetected(d);  
    }
  }, [dimensions])

  useEffect(() => {
    if (detected && location) {
      const rowSizeDetected = 12 / gridOptions[detected];
      setRowSize(rowSizeDetected);
      const rowsDetected = Math.ceil(totalItems / rowSizeDetected);
      setRows(rowsDetected);
      setHeight(rowsDetected * rowHeight);
      const urlParams = new URLSearchParams(location.search);
      const initialItemsOffset = parseInt(urlParams.get('start'), 10) || 0;
      const skipRows = Math.ceil(initialItemsOffset / rowSizeDetected);
      const margin = rowHeight * skipRows;
      // console.log('detected', rowSizeDetected, rowsDetected, rowHeight, gridOptions[detected], 'initialItemsOffset', initialItemsOffset, 'skipRows', skipRows,'margin', margin)
      setMarginTop(margin)
    }
  },[detected])

  useEffect(() => {

  }, [])

  return {
    detected,
    rows,
    marginTop,
    height,
    rowSize,
  };

}

export default useGridDetection;