import React, { useEffect, useRef, useState, useCallback, useContext } from "react"

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import { Virtuoso } from 'react-virtuoso'
import { useLocation, globalHistory } from "@reach/router";
import useIntersection from '../../hooks/useIntersection';
import useGridDetection from '../../hooks/useGridDetection';

export const NavigatorContext = React.createContext();

export const ObservableComponent = props => {
  const { id, navigatorRef, ready } = props;
  // console.log('id', id, ready)
  let navigator = useContext(NavigatorContext);
  if (props.navigator) {
    navigator = props.navigator ;
  }

  const ref = useIntersection((element) => {
    // console.log('id', id)
    // console.log('id', id, element.intersectionRatio, navigator.min.current.y, element.boundingClientRect.y);
    // navigator.min.current.id = id;
    if (ready && navigator.min.current.y > element.boundingClientRect.y) {
      // navigator.min.current.id = (id > navigator.min.current.id) ? navigator.min.current.id : id;
      navigator.min.current.id = id;
      // console.log('new min', navigator.min.current.id)
      navigator.min.current.ratio = element.intersectionRatio;
      navigator.min.current.x = element.boundingClientRect.x;
      navigator.min.current.y = element.boundingClientRect.y;
    } else if (ready && navigator.min.current.x >= element.boundingClientRect.x && navigator.min.current.y === element.boundingClientRect.y) {
      // navigator.min.current.id = (id > navigator.min.current.id) ? navigator.min.current.id : id;
      navigator.min.current.id = id;
      // console.log('new min 1', navigator.min.current.id)
      navigator.min.current.ratio = element.intersectionRatio;
      navigator.min.current.x = element.boundingClientRect.x;
      navigator.min.current.y = element.boundingClientRect.y;
    }
  }, {
    root: navigatorRef,
    threshold: [0.4, 0.6, 0.8 ,1],
    rootMargin: '0px',
    delay: 100,
    id,
  });

  return (
    <Box ref={ref}>
      {props.children}
    </Box>
  )
}


const Navigator = props => {
  const location = useLocation();
  const virtuoso = useRef();
  const [initialItem, setInitialItem] = useState(props.initialItem || 0);
  const [initialRow, setInitialRow] = useState();
  const [initialOffset, setInitialOffset] = useState();
  const defaultMin = { x: 10000, y: 10000, id: props.initialItem || 0}
  const min = useRef(defaultMin);
  const [isScrolling, setScrolling] = useState(false);
  const { generate, overscan, gridOptions, breakpoints, totalItems, itemHeight, cardHeights, width = '100%', height = '100%' } = props;
  const gridDetection = useGridDetection({ gridOptions, breakpoints, totalItems, rowHeight: itemHeight, location });
  const dbLoaded = props.dbLoaded;
  const navigator = {
    min,
  }

  const GenerateItem = useCallback((navigator, offset, rowOffset, generate, ready) => (index) => {
    // console.log('g', offset, rowOffset, gridDetection.rowSize,  index)
    let finalOffset = (index > 0) ? rowOffset : 0;
    const itemOffset = (index > 0) ? offset : 0;
    const key = (index - finalOffset) * gridDetection.rowSize;
    const items = (index === 0 && offset > 0) ? offset : gridDetection.rowSize;
    const rowItems = Array.from(Array(items), (e, i) => i)
    
    return (
      <React.Fragment>
        {ready && <ObservableComponent key={key} id={key + itemOffset} navigator={navigator} ready={ready}>
          <Grid container direction="row" key={key} id={key} spacing={1}>
            {rowItems.map((idx) => {
              {/* console.log('gen', index, key + idx + itemOffset) */}

              return generate(key + idx + itemOffset, index)
              // return props.generate(db[key + idx + itemOffset], key + idx + offset, index, navigator)
            })}
          </Grid>
        </ObservableComponent>}
      </React.Fragment>
    )
  }, [gridDetection.rowSize])

  const handleRangeChange = useCallback(({ startIndex, endIndex }) => {
  }, [])

  const closestMultiple = (n, x) => {
    const calc = n - (n % x) 
    return calc
  }

  useEffect(() => {
    if (location && gridDetection.rowSize !== undefined) {
      const urlParams = new URLSearchParams(globalHistory.location.search);
      const start = parseInt(urlParams.get('start'), 10) || 0;
      // console.log('closestMultiple(Math.floor(start / gridDetection.rowSize), gridDetection.rowSize)', closestMultiple(Math.floor(start / gridDetection.rowSize), gridDetection.rowSize))
      setInitialRow( closestMultiple(Math.floor(start / gridDetection.rowSize), gridDetection.rowSize))
      setInitialOffset( start % gridDetection.rowSize )
      setInitialItem(start);
    }
  }, [initialItem, gridDetection, location])

  useEffect(() => {
    if (!isScrolling) {
      const urlParams = new URLSearchParams(globalHistory.location.search);
      const currentStart = parseInt(urlParams.get('start'), 10) || 0;
      if (currentStart !== min.current.id) {  
        urlParams.set('start', min.current.id);
        // console.log('before 1', globalHistory.location.search, location.search)
        globalHistory.navigate(`${globalHistory.location.pathname}?${urlParams.toString()}`, { state: location.state, replace: true});
        // console.log('after 1', globalHistory.location.search, location.search)
      }
    } else {
        min.current = {
          x: defaultMin.x,
          y: defaultMin.y,
          id: min.current.id,
        }
    }
  }, [isScrolling, defaultMin.x, defaultMin.y, location.state])


  const scrollCheck = (current) => {
    if (isScrolling !== current) {
      const urlParams = new URLSearchParams(globalHistory.location.search);
      const currentStart = parseInt(urlParams.get('start'), 10) || 0;
      if (currentStart !== min.current.id) {  
        urlParams.set('start', min.current.id);
        // console.log('before 2', globalHistory.location.search, location.search)
        globalHistory.navigate(`${globalHistory.location.pathname}?${urlParams.toString()}`, { state: location.state, replace: true});
        // console.log('after 2', globalHistory.location.search, location.search)
      }
    }
    setScrolling(current)
  }

  const listHeightChanged = useCallback(() => {
  
    const urlParams = new URLSearchParams(globalHistory.location.search);
    const currentStart = parseInt(urlParams.get('start'), 10) || 0;
    if (currentStart !== min.current.id) {
      // console.log('listHeightChanged NEW')

      urlParams.set('start', min.current.id);
      globalHistory.navigate(`${globalHistory.location.pathname}?${urlParams.toString()}`, { state: globalHistory.location.state, replace: true});
    }
    // console.log('listHeightChanged')
  }, [])
  
  const rowOffset = (initialOffset > 0) ? 1 : 0;
  const roundedTotalItems = Math.ceil(totalItems / gridDetection.rowSize) + rowOffset;
  const ready = (initialItem === 0) ? 
    (globalHistory && gridDetection.rows !== undefined && initialRow !== undefined) 
    : Boolean(globalHistory && gridDetection.rows !== undefined && dbLoaded && initialItem !== undefined && initialRow !== undefined) ;

  useEffect(() => {
    const urlParams = new URLSearchParams(globalHistory.location.search);
    urlParams.set('start', min.current.id);
    const currentStart = parseInt(urlParams.get('start'), 10) || 0;
    if (currentStart !== min.current.id) {
      globalHistory.navigate(`${globalHistory.location.pathname}?${urlParams.toString()}`, { state: globalHistory.location.state, replace: true});
    }  
  }, [])

  // console.log('min.current.id', min.current.id, initialRow, props.initialItem)
  // console.log('initialRow', initialRow)

  // console.log('hey', gridDetection.rows, gridDetection.rowSize, initialItem, defaultMin, initialRow, initialOffset, rowOffset, roundedTotalItems, cardHeights[gridDetection.detected], initialRow + rowOffset)
  // console.log('navigation ready',ready)
  // console.log('virtuoso', virtuoso)
  //   item={(i) => console.log('i', i)}
  //  1 hey 568  1 0 1 1 569 530 1 no
  //  2 hey 568  2 1 0 0 568 530 1 no
  //  3 hey 568  3 1 1 1 569 530 2 si
  //  4 hey 568  4 2 0 0 568 530 2 si
  //  5 hey 568  5 2 1 1 569 530 3 no
  //  6 hey 568  6 3 0 0 568 530 3 no
  //  7 hey 568  7 3 1 1 569 530 4 si
  //  8 hey 568  8 4 0 0 568 530 4 si
  //  9 hey 568  9 4 1 1 569 530 5 no
  // 10 hey 568 10 5 0 0 568 530 5 no
  // 11 hey 568 11 5 1 1 569 530 6 si
  // 12 hey 568 12 6 0 0 568 530 6 si
  // 13 hey 568 13 6 1 1 569 530 7 no
  // 14 hey 568 14 7 0 0 568 530 7 no
  // 15 hey 568 15 7 1 1 569 530 8 si
  // 16 hey 568 16 8 0 0 568 530 8 si


  // initialTopMostItemIndex={initialRow + rowOffset}


  return (
    <React.Fragment>
    {ready &&
      <NavigatorContext.Provider value={navigator}>
        <Virtuoso
          style={{ width, height }}
          ref={virtuoso}
          overscan={overscan || 100}
          itemHeight={cardHeights[gridDetection.detected]}
          totalCount={roundedTotalItems}
          item={GenerateItem(navigator, initialOffset, rowOffset, generate, ready)}
          initialTopMostItemIndex={initialRow}
          rangeChanged={handleRangeChange}
          scrollingStateChange={scrollCheck}
          totalListHeightChanged={listHeightChanged}
        />
      </NavigatorContext.Provider>
    }
    {!ready && (props.loading || <div>cargando...</div>)}
    </React.Fragment>
)}

export default Navigator
