import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const ScrollTop = (props) => {
  const { children, scroller, querySelector } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: scroller ? scroller.current : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = event => {
    const queries = querySelector || ['#back-to-top-anchor'];
    const anchors = queries.slice(0,1).map((querySelector) => 
      document.querySelector(querySelector)
    );
    anchors.forEach((anchor) => {
      if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    })
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

export default ScrollTop;
